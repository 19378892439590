
import Vue from "vue";
export default Vue.extend({
  name: "CallToAction",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    buttonText: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false
    },
    textStyle: {
      type: String,
      required: false
    },
    buttonStyle: {
      type: String,
      required: false
    }
  }
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.className},[_c('div',{staticClass:""},[_c('h3',{staticClass:"text-base font-semibold text-gray-800"},[_c('router-link',{class:`${_vm.textStyle ? _vm.textStyle : 'hover:text-green-900 text-green-900'}`,attrs:{"to":_vm.redirectUrl}},[_c('span',{staticClass:"absolute inset-0",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('p',{class:`${
          _vm.textStyle
            ? _vm.textStyle
            : ' mt-1 text-sm text-green-800 line-clamp-2 font-medium'
        }`},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{class:`${
        _vm.buttonStyle
          ? _vm.buttonStyle
          : 'bg-green-500 hover:bg-green-600 text-white px-2 py-1 cursor-pointer font-medium text-sm'
      }`},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }
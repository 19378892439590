
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import { appMapGetters } from "@/store/modules/app";

import { validateFields } from "@/helpers";
import { version } from "../../../package.json";
import { logoPath } from "@/helpers/generalHelpers";
import CallToAction from "./CallToAction.vue";
import { getSystemFilesURL } from "@/helpers";

interface ILoginData {
  version: string;
  loggingIn: boolean;
  error: string;
  credentials: {
    email: string;
    password: string;
  };
  hasValidCredentials: boolean;
  redirectTo: string;
  isAnAgent?: boolean;
}

export default Vue.extend({
  name: "login",
  data(): ILoginData {
    return {
      version,
      loggingIn: false,
      error: "",
      credentials: {
        email: "",
        password: ""
      },
      hasValidCredentials: false,
      redirectTo: "",
      isAnAgent: false
    };
  },
  mounted() {
    const emailFieldRef = this.$refs.field_email as any;
    this.redirectTo = this.$route.query.redirectFrom
      ? (this.$route.query.redirectFrom as string)
      : "/";
    if (emailFieldRef && emailFieldRef.$el) {
      const input = emailFieldRef.$el.querySelector("input");
      if (input) {
        input.focus();
      }
    }
    if (this.$route.query && this.$route.query.email) {
      this.credentials.email = this.$route.query.email as string;
    }
  },
  watch: {
    $isLoggedIn: function(loggedIn) {
      this.$nextTick(function() {
        if (loggedIn) {
          this.$router.push("/").catch(() => {});
          this.$gtag.event("login", { method: "app" });
        }
      });
    },
    $isLoginMfa: function(loginMfa) {
      this.$nextTick(function() {
        if (loginMfa) {
          this.$router.push("/mfa").catch(() => {});
        }
      });
    },
    "credentials.email": {
      handler() {
        this.updateIsValid();
      }
    },
    "credentials.password": {
      handler() {
        this.updateIsValid();
      }
    }
  },
  methods: {
    ...authMapActions(["login"]),
    getLogoPath() {
      return logoPath();
    },
    async loginToApp(): Promise<void> {
      const loginTimer = this.__gtagMeasureTiming("login");
      this.error = "";
      this.loggingIn = true;
      const loading = this.$loading({
        lock: true
      });
      try {
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          const { email, password } = this.credentials;
          await this.login({ email, password });
        } else {
          this.error = "Please provide a username and password.";
        }
      } catch (e) {
        const { message, status } = e as any;
        this.error = message;
        if (status === 423) {
          this.$router.push({
            path: "/emergency-maintenance",
            query: { message: message }
          });
        } else if (status === 403) {
          this.$router.push({ path: "/403", query: { message: message } });
        }
        this.$log("login failed", "Error", {
          email: this.credentials.email,
          message: message
        });
        this.$bugSnagClient.notify(e);
      } finally {
        loading.close();
        this.loggingIn = false;
        loginTimer.done();
      }
    },
    async loginFormOnEnterEmail() {
      const passwordFieldRef = this.$refs.field_password as any;
      const passwordField = passwordFieldRef.$el.querySelector("input");
      if (this.credentials.email) {
        if (passwordField) passwordField.focus();
      }
    },
    async loginFormOnEnterPassword() {
      const emailFieldRef = this.$refs.field_email as any;
      const emailField = emailFieldRef.$el.querySelector("input");
      if (this.credentials.email && this.credentials.password) {
        await this.loginToApp();
      } else if (!this.credentials.email && this.credentials.password) {
        if (emailField) emailField.focus();
      }
    },
    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
      this.error = "";
    }
  },
  components: { CallToAction },
  computed: {
    ...appMapGetters(["getAppViewSettings", "getAppTheme"]),
    primaryBackgroundImage() {
      return getSystemFilesURL("AdobeStock_2867459232");
    },
    bgPattern() {
      return getSystemFilesURL("bg-pattern1");
    },
    isStaging(): boolean {
      return ["staging", "development"].includes(
        process?.env?.VUE_APP_ENV || ""
      );
    },
    getLoginPageBaseURL(): string {
      return process.env.VUE_APP_ENV === "production"
        ? "https://www.atlasgeneral.com/"
        : "/";
    }
  }
});
